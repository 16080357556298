<template>
  

    <v-app>
      <topmenubar />
      <v-main class="auth-bg">
        <v-container fill-height class="pa-0">
          <v-row class="full-height">
            <v-col cols="0" md="1"></v-col>
            <v-col cols="12" md="12" lg="5" class="my-auto">
                  <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>

</template>

<script>
import topmenubar from "../../components/topmenubar.vue";
export default {
  components: {
    topmenubar,
  },
};
</script>

<style>
.h-100 {
  height: 100%;
}
.d-grid {
  display: grid;
}
.place-items-center {
  place-items: center;
}
.position-relative {
  position: relative;
}
.auth-bg{
  background-image: url("../../assets/login-bg.png") !important;
  background-position: center bottom;
  background-size: cover;
}
</style>
